import React, { Fragment } from "react";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";

const NotFoundComponent = (props) => (
  <Fragment>
    <div className="pnf-container">
      <div className="text-center">
        <h1>404</h1>
        <h3 className="text-danger">PAGE NOT FOUND</h3>
        <p>Sorry! The page you are looking for is not found.</p>
        <Button
          className="btn-icon btn-shadow btn-outline-1x mt-3 btn-lg btn-square"
          outline
          title="Go Back"
          color="danger"
          onClick={() => props.history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  </Fragment>
);

export default withRouter(NotFoundComponent);
