import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import PageNotFound from "../PageNotFound";

import { ToastContainer } from "react-toastify";

import authTokenSet from "../../DemoPages/Auth";

const UserPages = lazy(() => import("../../DemoPages/UserPages"));

const User = lazy(() => import("../../DemoPages/User"));
const Property = lazy(() => import("../../DemoPages/Property"));
const City = lazy(() => import("../../DemoPages/City"));
//price management
const Price = lazy(() => import("../../DemoPages/Price"));
const Cluster = lazy(() => import("../../DemoPages/Cluster"));
const Overall = lazy(() => import("../../DemoPages/Overall"));
const SummarySheets = lazy(() => import("../../DemoPages/SummarySheets"));
const MIS = lazy(() => import("../../DemoPages/MIS"));

const AppMain = (props) => {
  //let token = localStorage.getItem('jwtTokenReCity');
  let roleId = +localStorage.getItem("loggedInRoleId");
  console.log(roleId);
  console.log(typeof roleId);
  let token = null;
  if (localStorage.jwtTokenReCity) {
    token = localStorage.getItem("jwtTokenReCity");
  } else {
    authTokenSet(false);
    // props.history.push("/auth/login");
    window.location.href = "#/auth/login";
  }

  return (
    <Fragment>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
              <h6 className="mt-5">
                Please wait while we load the components.
              </h6>
            </div>
          </div>
        }
      >
        <Switch>
          {roleId === 1 && <Route path="/city" component={City} />}
          {roleId === 1 && <Route path="/price" component={Price} />}
          {(roleId === 1 || roleId === 2) && (
            <Route path="/user" component={User} />
          )}
          {(roleId === 1 || roleId === 2) && (
            <Route path="/property" component={Property} />
          )}
          {(roleId === 1 || roleId === 2) && (
            <Route path="/cluster" component={Cluster} />
          )}
          <Route path="/mis-sheet" component={MIS} />
          <Route path="/auth" component={UserPages} />
          <Route path="/summary-sheets" component={SummarySheets} />
          <Route path="/overall" component={Overall} />
          {token != null ? (
            <Route
              exact
              path="/"
              render={() => <Redirect to="/overall/basic-detail/2.0" />}
              // render={() => <Redirect to="/overall/basic-details" />}
            />
          ) : (
            <Route
              exact
              path="/"
              render={() => <Redirect to="/auth/login" />}
            />
          )}
          {/* <Route exact path="*">
            <PageNotFound />
          </Route> */}
        </Switch>
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
