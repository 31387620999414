// action types
const SET_START_DATE = "SET_START_DATE";
const SET_END_DATE = "SET_END_DATE";
const SET_USER_DETAILS = "SET_USER_DETAILS";

// actions
export const setStartDate = (startDate) => ({
  type: SET_START_DATE,
  payload: startDate,
});

export const setEndDate = (endDate) => ({
  type: SET_END_DATE,
  payload: endDate,
});

export const setUserDetails = (details) => ({
  type: SET_USER_DETAILS,
  payload: details,
});

// initial state
const INITIAL_STATE = {
  startDate: new Date(),
  endDate: new Date(),
  userDetails: {},
};

// reducer
const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };

    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
          ? { ...state.userDetails, ...action.payload }
          : {},
      };

    default:
      return state;
  }
};

export default dashboardReducer;
