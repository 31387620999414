import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/base.scss";

import Main from "./DemoPages/Main";
import configureStore from "./config/configureStore";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import authTokenSet from "./DemoPages/Auth";
import axios from "axios";



axios.defaults.baseURL = "https://api.recity.in";
// axios.defaults.baseURL = "http://localhost:4000";
// axios.defaults.baseURL = "http://ec2-65-2-163-153.ap-south-1.compute.amazonaws.co";

const store = configureStore();
const persistor = persistStore(store);
const rootElement = document.getElementById("root");

let token = null;
if (localStorage.jwtTokenReCity) {
  token = localStorage.getItem("jwtTokenReCity");
  authTokenSet(token);
} else {
  authTokenSet(false);
}

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <PersistGate persistor={persistor}>
          <Component />
        </PersistGate>
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
