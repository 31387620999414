import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MetisMenu from "react-metismenu";

import { SuperMenu, CityMenu, DefaultMenu,ContractorMenu } from "./NavItems";

import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { errorToast } from "../../Reusable/toasts";

import { setCityName } from "../../reducers/ThemeOptions";

import axios from "axios";

class Nav extends Component {
  state = {
    roleId: null,
    blocking: true,
    loader: null,
  };

  componentDidMount() {
    let roleId = localStorage.getItem("loggedInRoleId");
    this.setState({ roleId: parseInt(roleId) });
    // console.log(this.props.cityName);
    // console.log(localStorage.getItem("loggedInCity"));
    if (!this.props.cityName) {
      this.props.setCityName(localStorage.getItem("loggedInCity"));
      // alert("done");
    }
    // this.filterMenu(SuperMenu);
  }

  filterMenu = (menu) => {
    // console.log("#######################################");
    // console.log(this.props.cityName);
    // console.log("ORIGINAL MENU :::", menu);
    const menuClone = JSON.parse(JSON.stringify(menu));
    const elementIndex = menuClone.findIndex(
      (el) => el.label.toLowerCase() === "reports"
    );
    const itemClone = { ...menuClone[elementIndex] };
    // console.log("itemClone", itemClone);
    // const itemIndex = menuClone[elementIndex].content.findIndex(
    //   (el) => el.label.toLowerCase() === "bin clean up"
    // );
    const updatedContent = itemClone.content.filter(
      (el) =>
        el.label.toLowerCase() !== "bin clean up" &&
        el.label.toLowerCase() !== "attendance"
    );
    // itemClone.content.splice(itemIndex, 1);
    // console.log("updated", updatedContent);
    itemClone.content = updatedContent;
    menuClone[elementIndex] = itemClone;
    // console.log("final", menuClone);
    // console.log("start", menu);
    return menuClone;
  };


  filterNainitalMenu = (menu) => {
   console.log("#######################################");
     console.log(this.props.cityName);
    // console.log("ORIGINAL MENU :::", menu);
    const menuClone = JSON.parse(JSON.stringify(menu));
    const elementIndex = menuClone.findIndex(
      (el) => el.label.toLowerCase() === "reports"
    );
    const itemClone = { ...menuClone[elementIndex] };
    // console.log("itemClone", itemClone);
    // const itemIndex = menuClone[elementIndex].content.findIndex(
    //   (el) => el.label.toLowerCase() === "bin clean up"
    // );
    const updatedContent = itemClone.content.filter(
      (el) =>
         el.label.toLowerCase() !== "bin clean up"  &&
        el.label.toLowerCase() !== "material transfer" &&

        el.label.toLowerCase() !== "material recovery" ,


      
    );
    // itemClone.content.splice(itemIndex, 1);
    // console.log("updated", updatedContent);
    itemClone.content = updatedContent;
    menuClone[elementIndex] = itemClone;
    // console.log("final", menuClone);
    // console.log("start", menu);
    return menuClone;
  };

  filterContractorMenu = (menu) => {
    // console.log("#######################################");
    // console.log(this.props.cityName);
    // console.log("ORIGINAL MENU :::", menu);
    const menuClone = JSON.parse(JSON.stringify(menu));
    const elementIndex = menuClone.findIndex(
      (el) => el.label.toLowerCase() === "reports"
    );
    const itemClone = { ...menuClone[elementIndex] };
    
    const updatedContent = itemClone.content.filter(
      (el) =>
        el.label.toLowerCase() !== "bin clean up"
     );
    // itemClone.content.splice(itemIndex, 1);
    // console.log("updated", updatedContent);
    itemClone.content = updatedContent;
    menuClone[elementIndex] = itemClone;
    // console.log("final", menuClone);
    // console.log("start", menu);
    return menuClone;
  };




  fetchAPI = () => {
    // this.setState({ blocking: true }, () => {
    //   if (this.props.apiSuccess) {
    //     this.setState({ blocking: false });
    //   }
    // });
    // const wardsRequest = axios.post("api/collection/ward-number");
    // const propertyRequest = axios.get("api/propertyTypes/get-property-types");
    // this.setState({ blocking: true }, () => {
    //   axios
    //     .all([wardsRequest, propertyRequest])
    //     .then(
    //       axios.spread((...responses) => {
    //         this.setState({ blocking: false });
    //       })
    //     )
    //     .catch(err => {
    //       this.setState({ blocking: true }, () => {
    //         errorToast("Something went wrong! Please Login Again.");
    //       });
    //     });
    // });
  };

  render() {
    console.log("just in", this.state.roleId);
    const cityName = this.props.cityName.toLowerCase();
    return (
      <Fragment>
        <BlockUi
          tag="div"
          blocking={!this.props.apiSuccess}
          className="block-overlay"
          loader={<Loader color="#555" active type={this.state.loader} />}
        >
          <>
            <h5 className="app-sidebar__heading">
              MSWM - {this.props.cityName}
            </h5>
            {this.state.roleId === 1 ? (
              <MetisMenu
                content={
                  cityName === "konark" ? this.filterMenu(SuperMenu) : SuperMenu
                }
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            ) : this.state.roleId === 2 ? (
              <MetisMenu
                content={
                  cityName === "konark" ? this.filterMenu(CityMenu) : CityMenu
                    &&
                  cityName === "nainital" ? this.filterNainitalMenu(CityMenu) : CityMenu

                }
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            ) :
             this.state.roleId === 10 ? (
              <MetisMenu
                content={
                  cityName.toLowerCase() === "dalhousie"||cityName.toLowerCase() === "ponda" ? this.filterContractorMenu(ContractorMenu) : DefaultMenu
                }
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            ):
            this.state.roleId > 2 ? (
              <MetisMenu
              
                content={
                  cityName === "konark"
                    ? this.filterMenu(DefaultMenu)
                    : DefaultMenu
                }
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            )  :null}

          </>
        </BlockUi>
        {/* <h5 className="app-sidebar__heading">UI Components</h5>
                <MetisMenu content={ComponentsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                <h5 className="app-sidebar__heading">Dashboard Widgets</h5>
                <MetisMenu content={WidgetsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                <h5 className="app-sidebar__heading">Forms</h5>
                <MetisMenu content={FormsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
                <h5 className="app-sidebar__heading">Charts</h5>
                <MetisMenu content={ChartsNav} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = (state) => ({
  cityName: state.ThemeOptions.cityName,
  apiSuccess: state.ThemeOptions.apiSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  setCityName: (cityName) => dispatch(setCityName(cityName)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Nav)
);

// export default withRouter(Nav);
