import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ThemeOptions from "./ThemeOptions";
import dashboard from "./dashboard";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["dashboard"],
};

// export default {
//   ThemeOptions,
// };

const rootReducer = combineReducers({
  ThemeOptions,
  dashboard,
});

export default persistReducer(persistConfig, rootReducer);
