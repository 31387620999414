// let roleId = parseInt(localStorage.getItem('loggedInRoleId'));
// const SuperMenu = [];
// const CityMenu = [];
// if(roleId === 1){
//     console.log("superadmin");
const SuperMenu = [
  // {
  //   icon: "pe-7s-display1",
  //   label: "Dashboard",
  //   to: "#/overall/basic-details",
  // },
  {
    icon: "pe-7s-display1",
    label: "Dashboard 2.0",
    to: "#/overall/basic-detail/2.0",
  },
  {
    icon: "pe-7s-users",
    label: "User Management",
    content: [
      {
        label: "All Users",
        to: "#/user/all-users",
      },
      {
        label: "Add User",
        to: "#/user/add-user",
      },
    ],
  },
  {
    icon: "pe-7s-culture",
    label: "Property Management",
    content: [
      {
        label: "All Properties",
        to: "#/property/all-properties",
      },
      {
        label: "Add Property",
        to: "#/property/add-property",
      },
    ],
  },
  {
    icon: "pe-7s-map",
    label: "City Management",
    content: [
      {
        label: "All Cities",
        to: "#/city/all-cities",
      },
      {
        label: "Add City",
        to: "#/city/add-city",
      },
    ],
  },
  {
    icon: "pe-7s-graph1",
    label: "Price Management",
    content: [
      {
        label: "Edit Price",
        to: "#/price/edit-price",
      },
      {
        label: "History",
        to: "#/price/history",
      },
    ],
  },
  {
    icon: "pe-7s-network",
    label: "Cluster Management",
    content: [
      {
        label: "All Clusters",
        to: "#/cluster/all-clusters",
      },
      {
        label: "Add Cluster",
        to: "#/cluster/add-cluster",
      },
    ],
  },
  {
    icon: "pe-7s-graph2",
    label: "Reports",
    content: [
      {
        label: "Collection",
        to: "#/overall/collection-details",
      },
      {
        label: "Segregation",
        to: "#/overall/segregation-details",
      },
      {
        label: "Attendance",
        to: "#/overall/attendance-details",
      },
      {
        label: "Bin Clean up",
        to: "#/overall/bin-cleanup-details",
      },
      // {
      //   label: "Transfer Station",
      //   to: "#/overall/transfer-station-details",
      // },
      {
        label: "Material Transfer",
        to: "#/overall/material-transfer",
      },
      {
        label: "Material Recovery",
        to: "#/overall/material-recovery-details",
      },

      {
        label: "Summary Sheets",
        to: "#/summary-sheets",
      },
    ],
  },
  {
    icon: "pe-7s-display1",
    label: "Dashboard",
    to: "#/overall/basic-details",
  },
  // {
  //   icon: "pe-7s-display1",
  //   label: "MIS",
  //   to: "#/mis-sheet",
  // },
];

const CityMenu = [
  // {
  //   icon: "pe-7s-display1",
  //   label: "Dashboard",
  //   to: "#/overall/basic-details",
  // },
  {
    icon: "pe-7s-display1",
    label: "Dashboard 2.0",
    to: "#/overall/basic-detail/2.0",
  },
  {
    icon: "pe-7s-users",
    label: "User Management",
    content: [
      {
        label: "All Users",
        to: "#/user/all-users",
      },
      {
        label: "Add User",
        to: "#/user/add-user",
      },
    ],
  },
  {
    icon: "pe-7s-culture",
    label: "Property Management",
    content: [
      {
        label: "All Properties",
        to: "#/property/all-properties",
      },
      {
        label: "Add Property",
        to: "#/property/add-property",
      },
    ],
  },
  {
    icon: "pe-7s-network",
    label: "Cluster Management",
    content: [
      {
        label: "All Clusters",
        to: "#/cluster/all-clusters",
      },
      {
        label: "Add Cluster",
        to: "#/cluster/add-cluster",
      },
    ],
  },
  {
    icon: "pe-7s-graph2",
    label: "Reports",
    content: [
      {
        label: "Collection",
        to: "#/overall/collection-details",
      },
      {
        label: "Segregation",
        to: "#/overall/segregation-details",
      },
      {
        label: "Attendance",
        to: "#/overall/attendance-details",
        // to: '#'
      },
      {
        label: "Bin Clean up",
        to: "#/overall/bin-cleanup-details",
        // to: '#'
      },
      // {
      //   label: "Transfer Station",
      //   to: "#/overall/transfer-station-details",
      //   // to: '#'
      // },
      {
        label: "Material Transfer",
        to: "#/overall/material-transfer",
      },
      {
        label: "Material Recovery",
        to: "#/overall/material-recovery-details",
        // to: '#'
      },

      {
        label: "Summary Sheets",
        to: "#/summary-sheets",
      },
    ],
  },
  {
    icon: "pe-7s-display1",
    label: "Dashboard",
    to: "#/overall/basic-details",
  },
];

const DefaultMenu = [
  // {
  //   icon: "pe-7s-display1",
  //   label: "Dashboard",
  //   to: "#/overall/basic-details",
  // },
  {
    icon: "pe-7s-display1",
    label: "Dashboard 2.0",
    to: "#/overall/basic-detail/2.0",
  },
  {
    icon: "pe-7s-graph2",
    label: "Reports",
    content: [
      {
        label: "Collection",
        to: "#/overall/collection-details",
      },
      {
        label: "Segregation",
        to: "#/overall/segregation-details",
      },
      {
        label: "Attendance",
        to: "#/overall/attendance-details",
        // to: '#'
      },
      {
        label: "Bin Clean up",
        to: "#/overall/bin-cleanup-details",
        // to: '#'
      },
      // {
      //   label: "Transfer Station",
      //   to: "#/overall/transfer-station-details",
      //   // to: '#'
      // },
      {
        label: "Material Transfer",
        to: "#/overall/material-transfer",
      },
      {
        label: "Material Recovery",
        to: "#/overall/material-recovery-details",
        // to: '#'
      },

      {
        label: "Summary Sheets",
        to: "#/summary-sheets",
      },
    ],
  },
  {
    icon: "pe-7s-display1",
    label: "Dashboard",
    to: "#/overall/basic-details",
  },
];



const ContractorMenu = [
  // {
  //   icon: "pe-7s-display1",
  //   label: "Dashboard",
  //   to: "#/overall/basic-details",
  // },
  {
    icon: "pe-7s-display1",
    label: "Dashboard 2.0",
    to: "#/overall/basic-detail/2.0",
  },
  {
    icon: "pe-7s-graph2",
    label: "Reports",
    content: [
      {
        label: "Collection",
        to: "#/overall/collection-details",
      },
      {
        label: "Segregation",
        to: "#/overall/segregation-details",
      },
      {
        label: "Attendance",
        to: "#/overall/attendance-details",
        // to: '#'
      },  {
        label: "Summary Sheets",
        to: "#/summary-sheets",
      },
    ]
  },
  {
    icon: "pe-7s-display1",
    label: "Dashboard",
    to: "#/overall/basic-details",
  },
]    

export { SuperMenu, CityMenu, DefaultMenu,ContractorMenu};

// export const MainNav = [];
