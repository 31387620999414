import React, { Fragment } from "react";

import Ionicon from "react-ionicons";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import axios from "axios";
import { errorToast, legendToast } from "../../../Reusable/toasts";
import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/avatar1.jpg";

import authTokenSet from "../../../DemoPages/Auth";

import { connect } from "react-redux";
import { setCityName } from "../../../reducers/ThemeOptions";
import {
  setStartDate,
  setEndDate,
  setUserDetails,
} from "../../../reducers/dashboard";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      name: "",
      username: "",
      qrcodeImage: "",
      role: "",
    };
    // logoutUserHandler = this.logoutUserHandler.bind(this);
  }

  componentDidMount() {
    let name = localStorage.getItem("loggedInName");
    let username = localStorage.getItem("loggedInUserId");
    let fcmToken= localStorage.getItem("jwtTokenReCity")
    let qrcodeImage = localStorage.getItem("loggedInQrcodeImage");
    let loggedInRoleId = parseInt(localStorage.getItem("loggedInRoleId"));
    let role = "";
    switch (loggedInRoleId) {
      case 1:
        role = "Super Admin";
        break;
      case 2:
        role = "City Admin";
        break;
      default:
        break;
    }

    if (name != "" && name != undefined) {
      this.setState({ name, username, fcmToken, qrcodeImage, role });
    }
  }

  // notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
  //     transition: Bounce,
  //     closeButton: true,
  //     autoClose: 5000,
  //     position: 'bottom-center',
  //     type: 'success'
  // });

  logoutUserHandler = () => {
    localStorage.clear();
    this.props.setStartDate(new Date());
    this.props.setEndDate(new Date());

    const dataReq = {
      username: this.state.username,
      fcmToken: this.state.fcmToken.replace("Bearer ",""),
    };
    axios.post("api/users/logout", dataReq)
    .then(({ data: { message, statusCode } }) => {
        authTokenSet(false);
        window.location.href = "#/auth/login";
        this.props.setCityName("");
        this.props.setUserDetails(null);
    })
    .catch((err) => {      
      errorToast();
      console.log(err);
    });
    // localStorage.removeItem('jwtTokenReCity');
    // localStorage.removeItem("loggedInRoleId");
    // localStorage.removeItem("loggedInState");
    // localStorage.removeItem("loggedInCity");
    // localStorage.removeItem("loggedInName");
    // localStorage.removeItem("loggedInQrcodeImage");
    
  };

  render() {
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <img
                      width={42}
                      className="avatar-icon rounded-circle"
                      style={{ display: "initial" }}
                      src={this.state.qrcodeImage}
                      alt=""
                    />
                    <FontAwesomeIcon
                      className="ml-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    className="rm-pointers dropdown-menu-lg pb-0"
                    style={{ paddingBotton: "0px" }}
                  >
                    <div
                      className="dropdown-menu-header mb-0"
                      style={{ paddingBotton: "0" }}
                    >
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: "url(" + city3 + ")",
                          }}
                        />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <img
                                  width={42}
                                  className="avatar-icon rounded"
                                  style={{ display: "initial" }}
                                  src={this.state.qrcodeImage}
                                  alt=""
                                />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {this.state.name}
                                </div>
                                <div className="widget-subheading opacity-8">
                                  {this.state.role}
                                </div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                  onClick={this.logoutUserHandler}
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="scroll-area-xs" style={{
                                            height: '150px'
                                        }}>
                                            <PerfectScrollbar>
                                                <Nav vertical>
                                                    <NavItem className="nav-item-header">
                                                        Activity
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            Chat
                                                            <div className="ml-auto badge badge-pill badge-info">8</div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">Recover Password</NavLink>
                                                    </NavItem>
                                                    <NavItem className="nav-item-header">
                                                        My Account
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            Settings
                                                            <div className="ml-auto badge badge-success">New</div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            Messages
                                                            <div className="ml-auto badge badge-warning">512</div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            Logs
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </PerfectScrollbar>
                                        </div>
                                        <Nav vertical>
                                            <NavItem className="nav-item-divider mb-0"/>
                                        </Nav>
                                        <div className="grid-menu grid-menu-2col">
                                            <Row className="no-gutters">
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                        outline color="warning">
                                                        <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> </i>
                                                        Message Inbox
                                                    </Button>
                                                </Col>
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                        outline color="danger">
                                                        <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> </i>
                                                        <b>Support Tickets</b>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Nav vertical>
                                            <NavItem className="nav-item-divider"/>
                                            <NavItem className="nav-item-btn text-center">
                                                <Button size="sm" className="btn-wide" color="primary">
                                                    Open Messages
                                                </Button>
                                            </NavItem>
                                        </Nav> */}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading">{this.state.name}</div>
                <div className="widget-subheading">{this.state.role}</div>
              </div>

              {/* <div className="widget-content-right header-user-info ml-3">
                                <Button className="btn-shadow p-1" size="sm" onClick={this.notify2} color="info"
                                        id="Tooltip-1">
                                    <Ionicon color="#ffffff" fontSize="20px" icon="ios-calendar-outline"/>
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
                                    Click for Toastify Notifications!
                                </UncontrolledTooltip>
                            </div> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCityName: (cityName) => dispatch(setCityName(cityName)),
  setStartDate: (startDate) => dispatch(setStartDate(startDate)),
  setEndDate: (endDate) => dispatch(setEndDate(endDate)),
  setUserDetails: (details) => dispatch(setUserDetails(details)),
});

export default connect(
  null,
  mapDispatchToProps
)(UserBox);
