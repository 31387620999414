import { toast, Bounce } from "react-toastify";

let simpleToastId = null;
let notifyToastId = null;
let warningToastId = null;
let successToastId = null;
let errorToastId = null;
let failedToastId = null;
let legendToastId = null;

export const simpleToast = (msg) => {
  if (!toast.isActive(simpleToastId)) {
    legendToastId = toast(msg, {
      render: msg,
      closeButton: true,
      autoClose: 2000,
      hideProgressBar: true,
      position: "bottom-center",
      type: "info",
    });
  }
};

export const notifyToast = (msg = "Work in Progress !", autoClose = 5000) => {
  if (!toast.isActive(notifyToastId)) {
    notifyToastId = toast(msg, {
      render: msg,
      transition: Bounce,
      closeButton: true,
      autoClose,
      position: "bottom-center",
    });
  }
};

export const warningToast = (msg) => {
  if (!toast.isActive(warningToastId)) {
    warningToastId = toast(msg, {
      render: msg,
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "warning",
    });
  }
};

export const successToast = (
  msg = "Your Data has been Successfully Submitted !",
  autoClose = 5000
) => {
  if (!toast.isActive(successToastId)) {
    successToastId = toast(msg, {
      render: msg,
      transition: Bounce,
      closeButton: true,
      autoClose,
      position: "bottom-center",
      type: "success",
    });
  }
};

export const errorToast = (
  msg = "Something Went Wrong. Please Try Again !"
) => {
  if (!toast.isActive(errorToastId)) {
    errorToastId = toast(msg, {
      render: msg,
      transition: Bounce,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-center",
      type: "error",
    });
  }
};

export const failedToast = (
  msg = "Something Went Wrong. Please Try Again !"
) => {
  if (!toast.isActive(failedToastId)) {
    failedToastId = toast(msg, {
      render: msg,
      transition: Bounce,
      closeButton: true,
      autoClose: 15000,
      position: "bottom-center",
      type: "error",
    });
  }
};

export const legendToast = (msg) => {
  if (!toast.isActive(legendToastId)) {
    legendToastId = toast(msg, {
      render: msg,
      closeButton: true,
      autoClose: false,
      position: "bottom-right",
    });
  }
};
